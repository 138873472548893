import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Strong, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Accueil | Bienvenue à CoreFlex Gym
			</title>
			<meta name={"description"} content={"CoreFlex Gym est votre première destination pour des expériences de remise en forme transformatrices"} />
			<meta property={"og:title"} content={"Accueil | Bienvenue à CoreFlex Gym"} />
			<meta property={"og:description"} content={"CoreFlex Gym est votre première destination pour des expériences de remise en forme transformatrices"} />
			<meta property={"og:image"} content={"https://lyongourmetgold.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
			<meta name={"msapplication-TileImage"} content={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(0, 0, 0, 0.7) 0%,rgba(0, 0, 0, 0.6) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://lyongourmetgold.com/images/1.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					CoreFlex Gym
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 100px 0px 100px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					CoreFlex Gym est votre première destination pour des expériences de remise en forme transformatrices. Nos portes sont ouvertes à tous ceux qui cherchent à améliorer leur santé physique et à repousser leurs limites. Grâce à un environnement favorable et à un large éventail d'équipements, votre parcours de remise en forme commence et s'épanouit ici.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
					justify-content="center"
					align-items="center"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="--color-primary"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="#3f24d8"
						hover-background="rgba(63, 36, 216, 0)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Contactez-nous
					</Button>
				</Box>
			</Box>
		</Section>
		<Section
			padding="88px 0 88px 0"
			sm-padding="60px 0 60px 0"
			box-shadow="--m"
			background="--color-light"
			md-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-25"
		>
			<Override slot="SectionContent" max-width="1220px" sm-min-width="280px" />
			<Box
				width="100%"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				margin="0px 0px 64px 0px"
				display="flex"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 28px/1.2 --fontFamily-sans"
					text-align="center"
					md-font="--headline3"
				>
					Découvrez CoreFlex Gym
				</Text>
				<Text
					color="--darkL2"
					text-align="center"
					lg-width="100%"
					margin="0px 0px 0px 0px"
					font="--base"
					width="70%"
					lg-max-width="720px"
				>
					Plongez dans une communauté dédiée au bien-être. CoreFlex Gym propose une vaste sélection d'équipements et de cours, adaptés à votre parcours de remise en forme unique. Que vous souhaitiez soulever des charges lourdes, accélérer votre cardio ou améliorer votre stabilité, nous vous fournissons les outils nécessaires à votre réussite.
				</Text>
				<Text
					margin="30px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 28px/1.2 --fontFamily-sans"
					text-align="center"
					md-font="--headline3"
				>
					Pourquoi CoreFlex Gym ?
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="24px"
				lg-grid-gap="16px"
				md-grid-template-columns="1fr"
			>
				<Box
					display="flex"
					padding="46px 26px 47px 26px"
					background="#FFFFFF"
					flex-direction="column"
					justify-content="flex-start"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase" text-align="center">
							Ensemble d'équipements tout compris
						</Strong>
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Zones d'entraînement de la force, du cardio et de la souplesse, équipées pour les novices comme pour les pros.
					</Text>
				</Box>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 26px 47px 26px"
					background="#FFFFFF"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase" text-align="center">
							Esprit de communauté
						</Strong>
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						text-align="center"
					>
						Rejoignez un réseau de personnes partageant les mêmes idées et qui se motivent mutuellement pour atteindre des objectifs communs.
					</Text>
				</Box>
				<Box
					display="flex"
					background="#FFFFFF"
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 26px 47px 26px"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--lead" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase">
							Des conseils d'experts
						</Strong>
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Des formateurs certifiés sont présents pour s'assurer que vous êtes sur la bonne voie, en matière de sécurité et d'efficacité.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://lyongourmetgold.com/images/2.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					CoreFlex Gym -{" "}
					<br />
					Renforcez vos muscles abdominaux et votre potentiel de flexion
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});